<template>
    <div>
        <v-row no-gutters fluid class="spacing-playground my-6 p-0">
            <v-col cols="12" sm="8">
                <div class="text-sm-h4">Меню</div>
            </v-col>
<!--            <v-col cols="12" sm="4" align="right">-->
<!--                <PagesList ref="pagelist" />-->
<!--            </v-col>-->
        </v-row>
        <v-card outlined>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Название</th>
                        <th class="text-left">slug</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in menu.menu">
                        <td>{{item.name}}</td>
                        <td>{{item.slug}}</td>
                    </tr>
                    </tbody>
                </template>
                {{menu}}
            </v-simple-table>
<!--            <v-progress-linear indeterminate v-if="menu"></v-progress-linear>-->
        </v-card>

    </div>
</template>

<script>
    import { db } from '../firebase';
    import PagesList from "../components/ui/PagesList";

    export default {
        name: "Menu",
        components: {PagesList},
        data() {
            return {
                menu: [],
            }
        },
        methods: {
            editMenuItem() {
                this.$refs.pagelist.editPageList({});
            },
            // async getMenu() {
            //     const snapshot = await db.collection('menu').get();
            //     let that = this;
            //     snapshot.forEach(doc => {
            //         console.log(doc.data())
            //         that.menu.push(doc.data())
            //     });
            // },
        },

        mounted() {
            // this.getMenu();
        },
        firestore: {
            menu: db.collection('settings').doc('menu'),
        },
    }
</script>

<style scoped>

</style>
