<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" elevation="1" large v-on="on">Добавить</v-btn>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Страницы
            </v-card-title>

            <v-card-text>
                <v-text-field v-model="title" label="Название меню" dense outlined></v-text-field>
                <v-select :items="pageList" v-model="page" v-on:change="setName" label="Страница" dense outlined></v-select>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="savePageMenu">Добавить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "PagesList",

        data() {
            return {
                dialog: false,
                pages: [],
                title: '',
                page: null
            }
        },
        computed: {
          pageList() {
              return this.pages.map((e) => e.title + ' id:' + e.id + ' slug:' + e.slug)
          }
        },
        methods: {
            editPageList() {
                this.dialog = true;
            },
            setName() {
                const index = this.pageList.indexOf(this.page);
                this.title = this.pages[index].title;
            },
            async savePageMenu() {
                const that = this;
                const index = this.pageList.indexOf(this.page);
                if (index >= 0 && this.name !== '') {
                    let page = that.pages[index]; page.title = that.title;
                    await this.$db.collection('menu').doc().set(page).then(function () {
                        that.dialog = false
                    })
                } else {
                    that.dialog = false
                }
            },

            async getListOfPages() {
                const snapshot = await this.$db.collection('pages').get();
                let that = this;
                snapshot.forEach(doc => {
                    const page = doc.data();
                    that.pages.push({id: page.id, title: page.en.title, slug: page.en.slug})
                });
            }
        },
        mounted() {
            this.getListOfPages()
        },

    }
</script>

<style scoped>

</style>
